<template>
  <div class="events">
    <h1>Events For {{ user.user.name }}</h1>
    <EventCard v-for="event in event.events" :key="event.id" :event="event" />
    <router-link
      v-if="page != 1"
      :to="{ name: 'EventList', query: { page: page - 1 } }"
      rel="prev"
    >
      Prev Page
    </router-link>
    <router-link
      v-if="page * 3 < event.totalEvents"
      :to="{ name: 'EventList', query: { page: page + 1 } }"
      rel="next"
    >
      Next Page
    </router-link>
  </div>
</template>

<script>
// @ is an alias to /src
import EventCard from '@/components/EventCard.vue'
import { mapState } from 'vuex'

export default {
  name: 'EventList',
  components: {
    EventCard,
  },
  created() {
    this.$store.dispatch('event/fetchEvents', {
      perPage: 3,
      page: this.page,
    })
  },
  computed: {
    page() {
      return parseInt(this.$route.query.page) || 1
    },
    totalEvents() {
      return parseInt(this.$store.state.totalEvents)
    },
    ...mapState(['event', 'user']),
  },
}
</script>

<style scoped>
.events {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
